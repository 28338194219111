export default {
    common: {
        loading: 'Aan het laden',
        somethingWentWrong: 'Er ging iets mis!',
        close: 'Sluiten',
        submit: 'Verzenden',
        cancel: 'Annuleer',
        ok: 'OK',
        refresh: 'Herladen',
        yes: 'Ja',
        no: 'Nee',
        enter: 'Invoeren',
        create: 'Aanmaken'
    },
    limetype: {
        saveChanges: 'Wijzigingen opslaan',
        noChanges: 'Geen wijzigingen',
        updateSuccess: 'Succes! Het object is geüpdatet',
        requestError: code => `Verzoek mislukt met statuscode: ${code}`,
        error: 'Er is iets mis gegaan. Probeer opnieuw en neem contact op met een administrator als het probleem zich blijft voordoen.',
        filter: 'Filter',
        noData: 'Geen data gevonden'
    },
    auth: {
        login: 'Inloggen',
        loginUsingBankId: 'Inloggen met BankID',
        userDetails: 'Gebruikersgegevens',
        forgotPassword: 'Wachtwoord vergeten?',
        forgotPasswordInfo: "Vul je e-mailadres in en we sturen je een link naar een pagina waar je je wachtwoord kunt resetten.",
        resetLinkSent: 'We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen!',
        usernameLabel: 'Gebruikersnaam',
        passwordLabel: 'Wachtwoord',
        loginSuccess: 'Je bent nu ingelogd!',
        personalNumberInputLabel: 'JJJJMMDDNNNN',
        personalNumberLabel: 'Persoonnummer',
        emailLabel: 'E-mailadres',
        emailInputLabel: 'Vul je e-mailadres in',
        resetPassword: 'Wachtwoord resetten',
        newPasswordLabel: 'Nieuw wachtwoord',
        newPasswordInputLabel: 'Voer je nieuwe wachtwoord in',
        confirmPasswordLabel: 'Bevestig nieuw wachtwoord',
        resetPasswordSuccess: 'Je wachtwoord is gereset. Je wordt nu doorgestuurd naar de inlogpagina waar je in kunt loggen met je nieuwe wachtwoord.',
        "urn:grn:authn:no:bankid": 'Mobile or Web',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "Web",
        "urn:grn:authn:no:bankid:mobile": "Mobile",
        "urn:grn:authn:dk:nemid:poces": "Personal with code card",
        "urn:grn:authn:dk:nemid:moces": "Employee with code card",
        "urn:grn:authn:dk:nemid:moces:codefile": "Employee with code file",
        "urn:grn:authn:se:bankid:another-device": "BankID on another device",
        "urn:grn:authn:se:bankid:same-device": "BankID on this device",
        authMethodInputLabel: 'Kies een methode',
        authMethodLabel: 'Inlogmethode',
        phoneNumberLabel: 'Mobiel',
        phoneNumberInputLabel: 'XXXXXXXX',
        activateAccountInfo: "Vul je e-mailadres in en we sturen je een link naar een pagina waar je je wachtwoord kan instellen",
        activateAccount: "Activeer je account",
        activationLinkSent: "Activatiemail verzonden!",
        loginAgain: "Probeer opnieuw in te loggen",
        loggedOut: "Je bent uitgelogd!"
    },
    nav: {
        loggedInAs: 'Ingelogd als',
        logout: 'Uitloggen',
        start: 'Start',
        index: 'Lijst',
        create: 'Aanmaken'
    },
    startView: 'Startoverzicht',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Oeps, 404',
    pageNotFoundText: 'De pagina is niet gevonden'
}