var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "5", lg: "4", xl: "3" } },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "mb-3 d-block",
                        staticStyle: { "max-width": "200px", margin: "auto" },
                        attrs: { src: "/domain/" + _vm.logo },
                        on: {
                          "~error": function ($event) {
                            return _vm.logoFallback.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(_vm.loginComponent, {
                    tag: "login-method",
                    attrs: { "auth-method": _vm.authMethod },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }