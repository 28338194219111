var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      text: "",
                      color: "primary",
                      ripple: "",
                      small: "",
                    },
                  },
                  on
                ),
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("auth.activateAccount")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "ValidationObserver",
            { ref: "obs", attrs: { tag: "form" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-4" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("auth.activateAccountInfo")))]),
                  _vm._v(" "),
                  _c("validation-provider", {
                    ref: "activateEmail",
                    attrs: {
                      name: _vm.$t("auth.emailLabel"),
                      rules: "required|email",
                      mode: "passive",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return _c("v-text-field", {
                            ref: "emailInput",
                            attrs: {
                              "prepend-icon": "email",
                              label: _vm.$t("auth.emailLabel"),
                              type: "text",
                              "error-messages": _vm.getErrors(errors),
                              loading: _vm.loading,
                              disabled: _vm.loading,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          })
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.close")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        text: "",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.submit")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }