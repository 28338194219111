var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "5", lg: "4", xl: "3" } },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "mb-3 d-block",
                        staticStyle: { "max-width": "200px", margin: "auto" },
                        attrs: { src: "/storage/" + _vm.logo },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ValidationObserver",
                    { ref: "obs", attrs: { tag: "form" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "elevation-12",
                          attrs: { id: "reset-password-card" },
                        },
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "", color: "primary", flat: "" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("auth.activateAccount")) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("auth.emailLabel"),
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "email",
                                          name: "password",
                                          label: _vm.$t("auth.emailInputLabel"),
                                          "error-messages": errors,
                                          loading: _vm.loading,
                                          disabled:
                                            _vm.loading || _vm.disableForm,
                                          placeholder: _vm.isAutoFilled
                                            ? " "
                                            : "",
                                        },
                                        model: {
                                          value: _vm.form.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "email", $$v)
                                          },
                                          expression: "form.email",
                                        },
                                      })
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("auth.newPasswordLabel"),
                                  rules: _vm.passwordValidationRule,
                                  vid: _vm.$t("auth.newPasswordLabel"),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "lock",
                                          name: "password",
                                          label: _vm.$t(
                                            "auth.newPasswordInputLabel"
                                          ),
                                          type: "password",
                                          "error-messages":
                                            errors.length &&
                                            _vm.passwordRegex !== null
                                              ? _vm.passwordErrorMessage ||
                                                errors
                                              : errors,
                                          loading: _vm.loading,
                                          disabled:
                                            _vm.loading || _vm.disableForm,
                                          placeholder: _vm.isAutoFilled
                                            ? " "
                                            : "",
                                        },
                                        model: {
                                          value: _vm.form.password,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "password", $$v)
                                          },
                                          expression: "form.password",
                                        },
                                      })
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("validation-provider", {
                                attrs: {
                                  name: _vm.$t("auth.confirmPasswordLabel"),
                                  rules:
                                    "required|confirmed:" +
                                    _vm.$t("auth.newPasswordLabel"),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "lock",
                                          name: "password",
                                          label: _vm.$t(
                                            "auth.confirmPasswordLabel"
                                          ),
                                          type: "password",
                                          "error-messages": errors,
                                          loading: _vm.loading,
                                          disabled:
                                            _vm.loading || _vm.disableForm,
                                          placeholder: _vm.isAutoFilled
                                            ? " "
                                            : "",
                                        },
                                        model: {
                                          value: _vm.form.password_confirmation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "password_confirmation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.password_confirmation",
                                        },
                                      })
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    type: "submit",
                                    loading: _vm.loading,
                                    disabled: _vm.loading || _vm.disableForm,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("common.submit")) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }