var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "obs",
    attrs: { tag: "form" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var invalid = ref.invalid
          return _c(
            "v-card",
            { staticClass: "elevation-12", attrs: { id: "login-card" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary", flat: "" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("auth.loginUsingBankId"))),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("validation-provider", {
                    attrs: {
                      name: _vm.$t("auth.personalNumberLabel"),
                      rules: "required|digits:12",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                "prepend-inner-icon": "$vuetify.icons.bankid",
                                solo: "",
                                clearable: "",
                                "hide-details": !invalid,
                                label: _vm.$t("auth.personalNumberInputLabel"),
                                type: "tel",
                                "error-messages": errors,
                                disabled: _vm.loading,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.form.personalNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "personalNumber", $$v)
                                },
                                expression: "form.personalNumber",
                              },
                            })
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _vm.loading
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            "align-content": "center",
                            align: "center",
                            justify: "center",
                          },
                        },
                        [
                          _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: {
                              size: 20,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n        " + _vm._s(_vm.status) + "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        type: "submit",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("auth.login")) + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }